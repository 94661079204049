/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../components/Hero/Breadcrumb";
import Loading from "../components/Loading/Loading";
import { axiosInstance } from "../utils/axios";
import { GetDataLayerStateValue } from "../utils/context";

export default function CourseDetails() {
  // const [key, setKey] = useState("home");
  const [{ isLoading }, dispatch] = GetDataLayerStateValue();
  const [singleCourse, setSingleCourse] = useState({
    id: "",
    image: "",
    title: "",
    description: "",
    content: "",
  });
  const [tabValue, setTabValue] = useState(0);
  const url = window.location.pathname.replace("/courses/", "");

  const getSingleCourseDetails = useCallback(async() => {
    dispatch({ type: "SET_IS_LOADING", payload: true });
    await axiosInstance
      .get(`/api/v1/product/course/${url}`)
      .then((response) => {
        const data = response.data;
        setSingleCourse(data);
        dispatch({ type: "SET_IS_LOADING", payload: false });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, [dispatch, url]);
  useEffect(() => {
    getSingleCourseDetails();
  }, [getSingleCourseDetails]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <main id="main">
      <Breadcrumb page="Courses" title="Course Details" />
      <section id="team" className="team section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>{singleCourse.title}</h2>
          </div>

          <div
           className="courses-details-main-div"
          >
            <div
              className="course-details-tab-div"
            >
                <div>
                <a
                onClick={() => {
                  setTabValue(0);
                }}
                className={`${tabValue === 0 ? "tab-item active" : "tab-item"}`}
              >
                About the Course
              </a>
              <a
                onClick={() => {
                  setTabValue(1);
                }}
                className={`${tabValue === 1 ? "tab-item active" : "tab-item"}`}
              >
                The Modules
              </a>
              <a
                onClick={() => {
                  setTabValue(2);
                }}
                className={`${tabValue === 2 ? "tab-item active" : "tab-item"}`}
              >
                Enrollment
              </a>
              <a
                onClick={() => {
                  setTabValue(3);
                }}
                className={`${tabValue === 3 ? "tab-item active" : "tab-item"}`}
              >
                  Your Investment(Pay Now)
              </a>
                </div>
             
            </div>
            <div className="course-details-content-div">
              {tabValue === 0 && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: singleCourse.description,
                  }}
                />
              )}
              {tabValue === 1 && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: singleCourse.module,
                  }}
                />
              )}
              {tabValue === 2 && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: singleCourse.enrollment,
                  }}
                />
              )}
              {tabValue === 3 && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: singleCourse.your_investment,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}


