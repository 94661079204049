import React, { useCallback, useEffect, useState } from "react";
import BooksCard from "../components/Cards/BooksCard";
import Loading from "../components/Loading/Loading";
// import DefaultPodcastImage from "../img/default/default-album.jpg";
import { axiosInstance } from "../utils/axios";
import { GetDataLayerStateValue } from "../utils/context";

export default function BooksPage() {
	const [{ isLoading }, dispatch] = GetDataLayerStateValue();
	const [books, setBooks] = useState([]);

	const getBooks = useCallback(() => {
		dispatch({ type: "SET_IS_LOADING", payload: true });
		axiosInstance
			.get("/api/v1/product/books/")
			.then((response) => {
				const data = response.data;
				setBooks(data.results);
				dispatch({ type: "SET_IS_LOADING", payload: false });
			})
			.catch((error) => {
				console.log("Error when fetching books >>>>", error.response);
			});
	}, [dispatch]);

	useEffect(() => {
		if (books.length === 0) {
			getBooks();
		}
	}, [books, getBooks]);

	if (isLoading) {
		return <Loading />;
	}
	return (
		<main id="main">
			{/* <Breadcrumb page="Books" title="Our Books" /> */}
			<section id="team" className="team section-bg">
				<div className="container" data-aos="fade-up">
					<div className="section-title">
						<h2>Books</h2>
						<p>
							Get informed on all matters related to trading from our books
							written just for you
						</p>
					</div>

					<div className="row">
						{books.map((book) => {
							const { id, title, description, image, is_free, download_file } =
								book;
							return (
								<div className="col-lg-6 mb-4" key={id}>
									<BooksCard
										title={title}
										image={image}
										description={description}
										isFree={is_free}
										downloadFile={download_file}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</section>
		</main>
	);
}
