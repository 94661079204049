import React, { useState } from "react";
import { Link } from "react-router-dom";
import { EmailField, PasswordField } from "../components/Fields/Fields";
import { AuthLoader } from "../components/Loading/Loading";
import AlertMessage from "../components/Messages/Messages";
// import { axiosInstance } from "../utils/axios";
import { GetDataLayerStateValue } from "../utils/context";
import pageLinks from "../utils/links";
import { scrollToTop } from "../utils/utils";

var axios = require("axios");
var FormData = require("form-data");

const SignIn = () => {
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [{ email, password, client_id, showAlertMessage }, dispatch] =
    GetDataLayerStateValue();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsAuthLoading(true);
    // validate the form and submit
    if (email && password) {
      var data = new FormData();
      data.append("client_id", client_id);
      data.append("grant_type", "password");
      data.append("username", email);
      data.append("password", password);

      var config = {
        method: "post",
        url: "https://harmannkibue.opalstacked.com/o/token/",
        // url: 'http://127.0.0.1:8000/o/token/',
        data: data,
      };

		axios(config)
		.then(function (response) {
			const data = response.data;
			localStorage.setItem("access_token", data.access_token);
			localStorage.setItem("refresh_token", data.refresh_token);
			localStorage.setItem("token_expiry", data.expires_in);
			window.location.href = "/";
			dispatch({ type: "CLEAR_CREDENTIALS" });
		})
		.catch(function (error) {
			dispatch({
				type: "SHOW_ALERT_MESSAGE",
				alertClass: "danger",
				alertMessage: "Invalid username or password",
			});
			console.log("The signup error is >>>>", JSON.stringify(error.response));
		});
	}

    // 			window.location.href = "/";
    // 			dispatch({ type: "CLEAR_CREDENTIALS" });
    // 		})
    // 		.catch((error) => {
    // 			dispatch({
    // 				type: "SHOW_ALERT_MESSAGE",
    // 				alertClass: "danger",
    // 				alertMessage: "Invalid username or password",
    // 			});
    // 			console.log("THE client id passed is >>> ", client_id);
    // 			console.log("The signup error is >>>>", JSON.stringify(error.response));
    // 		});
    // }
  };
  return (
    <main>
      <section id="icon-boxes" className="icon-boxes login-area">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6 align-items-stretch mb-5">
              <div className="icon-box mt-5">
                <form onSubmit={handleSubmit}>
                  <h5 className="text-center mb-3">Sign In</h5>

                  <EmailField name="email" />

                  <PasswordField name="password" />

                  <div className="text-center mt-4">
                    <button className="btn btn-primary" type="submit">
                      {isAuthLoading ? <AuthLoader /> : "Login"}
                    </button>
                  </div>
                </form>
                {showAlertMessage && <AlertMessage />}
                <div className="mt-3">
                  <Link
                    to={pageLinks.signup.url}
                    className="float-left"
                    onClick={() => scrollToTop}
                  >
                    Sign up
                  </Link>
                  <Link
                    to={pageLinks.resetPassword.url}
                    className="float-right"
                    onClick={() => scrollToTop}
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SignIn;
