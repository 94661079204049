import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ArticlesCard from "../components/Cards/ArticlesCard";
import Breadcrumb from "../components/Hero/Breadcrumb";
import Loading from "../components/Loading/Loading";
import { axiosInstance } from "../utils/axios";

import { GetDataLayerStateValue } from "../utils/context";
// import pageLinks from "../utils/links";
import { scrollToTop } from "../utils/utils";
import PageNotFound from "./PageNotFound";

export default function BlogPage() {
  const [
    { isLoading, articlesUrl, nextUrl, previousUrl, articles, isError },
    dispatch,
  ] = GetDataLayerStateValue();
  const [pageNumber, setPageNumber] = useState(1);

  let { category } = useParams();


  const paginationButtonAction = (action) => {
    if (action === "next") {
      setPageNumber(pageNumber + 1);
      dispatch({ type: "SET_ARTICLES_URL", payload: nextUrl });
    } else if (action === "previous") {
      setPageNumber(pageNumber - 1);
      dispatch({ type: "SET_ARTICLES_URL", payload: previousUrl });
    }
    scrollToTop();
  };

  const getArticles = useCallback(() => {
    const searchString = window.location.search.replace("?search=", "");

    dispatch({ type: "SET_IS_LOADING", payload: true });
    dispatch({ type: "SET_IS_ERROR", payload: false });

    var categoryUrl = "";
    if (searchString) {
      categoryUrl = `/api/v1/content/articles/${searchString}/`;
    } else if (category === "technical-analysis") {
      categoryUrl = `/api/v1/content/articles/T/`;
    } else if (category === "psychology") {
      categoryUrl = `/api/v1/content/articles/P/`;
    } else if (category === "crypto") {
      categoryUrl = `/api/v1/content/articles/C/`;
    } else if (category === "educational") {
      categoryUrl = `/api/v1/content/articles/E/`;
    }
    // categoryUrl = `/api/v1/content/articles/`;


    axiosInstance
      .get(
        articlesUrl === "/api/v1/content/article/" ? categoryUrl : articlesUrl
      )
      .then((response) => {
        const data = response.data;
        dispatch({
          type: "SET_ARTICLES",
          payload: data.results,
          nextUrl: data.next
            ? data.next.replace("https://harmannkibue.opalstacked.com", "")
            : data.next,
          previousUrl: data.previous
            ? data.previous.replace("https://harmannkibue.opalstacked.com", "")
            : data.previous,
        });

        dispatch({ type: "SET_IS_LOADING", payload: false });
      })
      .catch((error) => {
        dispatch({ type: "SET_IS_LOADING", payload: false });
        dispatch({ type: "SET_IS_ERROR", payload: true });
      });


  }, [dispatch, articlesUrl, category]);

  useEffect(() => {
    getArticles();
  }, [getArticles]);

  if (isLoading || !articlesUrl) {
    return <Loading />;
  }

  if (isError) {
    return <PageNotFound />;
  }

  return (
    <main id="main">
      <Breadcrumb page="Articles" title="Articles" />
      <section id="blog" className="blog">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 entries">
              {articles.length === 0 ? (
                <h5 className="text-center mt-5">No articles found...</h5>
              ) : (
                articles.map((article) => {
                  const { id, slug, title, image, description, created } =
                    article;
                  const date = new Date(created).toDateString();
                  return (
                    <ArticlesCard
                      key={id}
                      id={id}
                      slug={slug}
                      title={title}
                      image={image}
                      description={description}
                      created={date}
                    />
                  );
                })
              )}
              {articles.length > 0 && (
                <div className="blog-pagination">
                  <ul className="justify-content-center">
                    <li>
                      {previousUrl && (
                        <span
                          onClick={() => paginationButtonAction("previous")}
                        >
                          Previous
                        </span>
                      )}
                    </li>
                    <li className="active">
                      <span>{pageNumber}</span>
                    </li>
                    <li>
                      {nextUrl && (
                        <span onClick={() => paginationButtonAction("next")}>
                          Next
                        </span>
                      )}
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {/* <!-- End blog entries list --> */}

            {/* <!-- End blog sidebar --> */}
            {/* <div className="col-lg-4">
              <BlogSidebar />
            </div> */}
            {/* <!-- End blog sidebar --> */}
          </div>
        </div>
      </section>
    </main>
  );
}
