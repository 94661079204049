import React, { useEffect } from "react";
import { GetDataLayerStateValue } from "../../utils/context";

export default function AlertMessage() {
	// const [showAlert, setShowAlert] = useState(false)
	const [{ alertClass, alertMessage, showAlertMessage }, dispatch] =
		GetDataLayerStateValue();

	useEffect(() => {
		setTimeout(() => {
			dispatch({ type: "CLEAR_ALERT_MESSAGES" });
		}, 10000);
	}, [showAlertMessage, dispatch]);

	if (showAlertMessage) {
		return (
			<div className={`alert alert-${alertClass} fade show my-3`} role="alert">
				{alertMessage}
			</div>
		);
	}
	return <span></span>;
}
