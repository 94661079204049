import React, { useEffect } from "react";
import Loading from "../../components/Loading/Loading";
import { axiosInstance } from "../../utils/axios";

import { GetDataLayerStateValue } from "../../utils/context";
import Card from "./Card";

export default function ArticlesSection() {
  const [
    { isLoading, articlesUrl, articles },
    dispatch,
  ] = GetDataLayerStateValue();


  useEffect(() => {
    dispatch({ type: "SET_IS_LOADING", payload: true });
    dispatch({ type: "SET_IS_ERROR", payload: false });
    var categoryUrl = `/api/v1/content/articles/`;

    axiosInstance
      .get(categoryUrl)
      .then((response) => {
        const data = response.data;
        dispatch({
          type: "SET_ARTICLES",
          payload: data.results,
          nextUrl: data.next
            ? data.next.replace("https://harmannkibue.opalstacked.com", "")
            : data.next,
          previousUrl: data.previous
            ? data.previous.replace("https://harmannkibue.opalstacked.com", "")
            : data.previous,
        });

        dispatch({ type: "SET_IS_LOADING", payload: false });
      })
      .catch((error) => {
        dispatch({ type: "SET_IS_LOADING", payload: false });
        dispatch({ type: "SET_IS_ERROR", payload: true });
      });
  }, [articlesUrl, dispatch]);

  if (isLoading || !articlesUrl) {
    return <Loading />;
  }
  const latestArticles = articles.slice(0, 3);
  return (
    <div className="home-articles-section">
      <h3>Latest Articles</h3>
      <div className="home-articles-section-div">
          {latestArticles.map((article) => {
            return <Card article={article}/>
          })}
      </div>
    </div>
  );
}
