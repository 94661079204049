/* eslint-disable jsx-a11y/scope */
import React from "react";
import Breadcrumb from "../components/Hero/Breadcrumb";

export default function FundingPage() {
	return (
		<main id="main">
			<Breadcrumb page="Funding " title="Funding Program" />
			<section id="blog" className="blog">
				<div className="container" data-aos="fade-up">
					<div className="row">
						<div className="col-md-12 entries">
							<article className="entry entry-single">
								<h2 className="entry-title">Learn, Trade, Earn</h2>
								<h4>Get funded $50,000 $100,000!</h4>

								<h2 className="entry-title mt-4">Introduction</h2>
								<div className="entry-content">
									<p>
										Financial Hub has developed a unique educational plus
										funding program for traders. Get a chance to trade $100,000
										if you can successfully complete the challenge.
									</p>

									{/* <blockquote>
										<p>
											Et vero doloremque tempore voluptatem ratione vel aut.
											Deleniti sunt animi aut. Aut eos aliquam doloribus minus
											autem quos.
										</p>
									</blockquote> */}

									<h2 className="entry-title mt-4">How it works</h2>
									<h3>Step 1</h3>
									<p>
										Enroll to the financial hub masterclass with a fee of $200.
										After this you will get lifetime access to the financial hub
										online video tutorials. After successfully going through the
										content you will get funded $1200 and you will start
										trading.
									</p>

									<h3>Step 2</h3>
									<p>
										After successfully going through the content you will get
										funded $1200 and you will start trading. Trading period is
										30 calendar days after which your subscription will expire.
										Thereafter you can renew your subscription by paying the
										subscription fee of Ksh 6,000. The live trading evaluation
										process consists of first 6 months’ challenge. Upon
										successfully meeting our basic condition of consistently
										hitting the profit target of not less than 10% for 6
										straight months and not being in a drawdown of more than 30%
										at the end of each month for the 6 straight months, you are
										guaranteed funding of up-to $100,000.
									</p>

									<h3>Account Rules</h3>
									<div className="table-responsive mb-4">
										<table className="table table-striped text-center">
											<thead className="text-uppercase bg-secondary">
												<tr className="text-white">
													<th scope="col">Trading Period</th>
													<th scope="col">30 calendar days</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td scope="row">
														Maximum Loss Allowed during the 30 Calendar Days
														Trading Period
													</td>
													<td className="text-danger">-30%</td>
												</tr>
												<tr>
													<td scope="row">
														Profit sharing with FinancialHub Every Two Weeks
													</td>
													<td>50/50</td>
												</tr>
												<tr>
													<td scope="row">
														Non-Refundable fee After 30 Calendar Days Trading
														Period
													</td>
													<td>Ksh.6,000</td>
												</tr>
											</tbody>
										</table>
										<h6 className="font-weight-bold text-center">
											<span className="text-danger">*</span>Open position can be
											held over the weekend. |{" "}
											<span className="text-danger">*</span>Use of automated
											Trading Systems/Robots is NOT ALLOWED.
										</h6>
									</div>

									<p>
										<span className="font-weight-bold">Point to Note: </span> At
										the end of the month if our 50% profit share equals to or is
										more than the (Ksh 6000 subscription fee +$ USD 41 ) you
										WON'T PAY anything for the next month.
									</p>
									<p>
										<span className="font-weight-bold">Point to Note: </span>
										CONDITION TO BE FUNDED: $50,000 to $100,000 : The trader
										must have achieved consistent monthly profit target of +10%
										and NO drawdown more than -25% end of every 30 Calendar Days
										for the first 6 month .If a trader passes the 6 month test
										period, we will review month by month performance and
										confirm the eligibility to be given a bigger account.
										FinancialHub will provide either $50,000 or $100,000 trading
										account depending on the trader's skills and performance
										displayed by that time
									</p>

									<h3>Payment</h3>
									<p>
										This program costs Ksh. 20,000 and $200 for international
										clients and is not payable in installments. This package
										also comes with a monthly subscription fee of Ksh. 6,000 if
										you trade and fail to attain 10%. No subscription for
										traders who make 10% or more each month.
									</p>
									<h3>Payment Details</h3>
									<p>
										All payments can flow to this paybill number :562694 Product
										sells for $200. or Ksh 20, 000
									</p>
								</div>
							</article>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}
