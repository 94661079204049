import React, { useCallback, useEffect, useState } from "react";
import { Preloader, Puff } from "react-preloader-icon";
import { axiosInstance } from "../../utils/axios";
import { scrollToTop } from "../../utils/utils";
// import SidebarLoader from "./SidebarLoader";
import SidebarNewsCard from "./SidebarNewsCard";

export default function BlogSidebar() {
  const [marketNews, setMarketNews] = useState([]);
  const [pagination, setPagination] = useState({
    url: "/api/v1/content/articles/m/",
    nextUrl: "",
    previousUrl: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const paginationAction = (action) => {
    if (action === "next") {
      console.log("next button clicked");
      setPagination({ ...pagination, url: pagination.nextUrl });
    } else if (action === "previous") {
      console.log("previous button clicked");
      setPagination({ ...pagination, url: pagination.previousUrl });
    }
    scrollToTop();
  };

  const getMarketNews = useCallback(() => {
    setIsLoading(true);
    axiosInstance
      .get(pagination.url)
      .then((response) => {
        const data = response.data;
        setMarketNews(data.results);
        setPagination({
          ...pagination,
          nextUrl: data.next,
          previousUrl: data.previous,
        });
        setIsError(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error when fetching market news >>>", error);
        setIsError(true);
      });
  }, [pagination]);

  useEffect(() => {
    getMarketNews();
  }, [getMarketNews]);

  return (
    <div className="sidebar">
      <h3 className="sidebar-title">Market News</h3>
      <div className="sidebar-item recent-posts">
        {isLoading && (
          <div className="text-center align-items-center">
            <Preloader
              className="text-center align-items-center"
              use={Puff}
              size={60}
              strokeWidth={6}
              strokeColor="#05579e"
              duration={2100}
            />
          </div>
        )}
        {isError ? (
          <small>An error occurred while fetching market news</small>
        ) : (
          marketNews.map((news) => {
            const { id, title, created, image, slug } = news;
            const newsDate = new Date(created).toDateString();
            return (
              <SidebarNewsCard
                key={id}
                title={title}
                date={newsDate}
                image={image}
                slug={slug}
              />
            );
          })
        )}
      </div>
      {marketNews.length > 0 && (
        <div className="blog-pagination">
          <ul className="justify-content-center">
            {pagination.previousUrl && (
              <li className="border border-warning">
                <span onClick={() => paginationAction("previous")}>
                  Previous
                </span>
              </li>
            )}

            {pagination.nextUrl && (
              <li className="border border-warning">
                <span onClick={() => paginationAction("next")}>Next</span>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
