import React from "react";
import Breadcrumb from "../components/Hero/Breadcrumb";
import mediabanner from "../img/fhub/banner-media.jpg"
import testimonials from "../img/fhub/testimonials.webp"

export default function Testimonials() {
    return (
        <>
            <Breadcrumb page="Testimonials" title="Testimonials" />
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">
                    

                    <div className="row content">
                        <div className="col-md-12 col-12 pt-4 pt-lg-0">

                            <div className="section-title">
                                <p> Success Stories from Financial Hub Forex School: Empowering the Next Generation of Skilled Traders. </p>
                            </div>
                            
                        </div>


                        <div class="col-md-12 col-12 pt-4 pt-lg-3" data-aos="fade-up">
                            <img width={"100%"} height={"auto"} src={testimonials} alt="Testimonials Banner" />
                        </div>




                        <div class="col-md-6 col-12 pt-4 pt-lg-3" data-aos="fade-up">
                            <iframe width="100%" height="546px" src="https://www.youtube.com/embed/x606vfqjkbc" title="Meet Faiza Ibrahim - Her Forex Journey with Financial Hub" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>



                        <div class="col-md-6 col-12 pt-4 pt-lg-3" data-aos="fade-up">

                            <iframe width="100%" height="546px" src="https://www.youtube.com/embed/akV8vrt9y8c" title="My Mum is My Main Motivator in Starting a Hedge Fund (Forex Student)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <div class="col-md-6 col-12 pt-4 pt-lg-3" data-aos="fade-up">
                            <iframe width="100%" height="546px" src="https://www.youtube.com/embed/umHqVeBwlIE" title="How Our Student Leroy is Trading his $200,000 Prop Challenge (Alpha Capital)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            <button className="student-portal-button"><a href="/contact">Get In Touch</a></button>
                        </div>

                        <div class="col-md-6 col-12 pt-4 pt-lg-3" data-aos="fade-up">
                            <iframe width="100%" height="546px" src="https://www.youtube.com/embed/REp9600T7yU" title="Meet Shiru: Her Journey with Forex at Financial Hub" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                            
                        </div>

                    </div>

                </div>
            </section>




        </>
    );
}
