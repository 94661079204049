import React from "react";

export default function TeamCard({ name, title, quote, image, linkedIn }) {
  return (
    <div className="col-lg-6 mb-3">
      <div className="team-member">
        <div className="pic align-items-center text-center">
          <img src={image} className="img-fluid" alt={name}></img>
          <h4>
            {name}{" "}
            <a href={linkedIn} target="_blank" rel="noreferrer">
              <i className="ri-linkedin-box-fill"></i>
            </a>
          </h4>
          <span>{title}</span>
          {/* <h6 className="about-separator"></h6> */}
        </div>

        <div className="member-info">
          <p>{quote}</p>
          {/* <div className="social">
            <a href="">
              <i className="ri-linkedin-box-fill"></i>{" "}
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
