import React from "react";
import { Link } from "react-router-dom";
import Hero from "../components/Hero/Hero";
import PackagesList from "../components/Packages/PackagesList";
import Partners from "../components/Partners/Partners";
import Ticker from "../components/Ticker/Ticker";

import pageLinks from "../utils/links";
import { scrollToTop } from "../utils/utils";
import pepperstoneBanner from "../img/fhub/pep-banner.webp";
import ArticlesSection from "../components/ArticlesSection";

import FreeTrialPopup from "../components/Popups";


export default function HomePage() {
  // var currentTime = new Date(1504095567183).toLocaleTimeString("en-US")
  let GDPR;
  let GDPR_CONSENT_755;
  return (
    <>

      <FreeTrialPopup />
      <Ticker />
      <Hero />


      {/* The main section starts */}

      <main id="main">
        {/* <!-- ======= Services Section ======= --> */}
        <section id="services" className="services large-margin">
          <div className="container">
            <div className="section-title">
              <h2>Services</h2>
              <p>Get to know the best of the services we offer.</p>
            </div>

            <div className="row">
              <div
                className="col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="icon-box">
                  <i className="bi bi-card-checklist"></i>
                  <h4>
                    <Link
                      to={pageLinks.products.url}
                      onClick={() => scrollToTop()}
                    >
                      Forex Courses
                    </Link>
                  </h4>
                  <p>
                    Become a professional forex trader with help  from our mentors. Learn
                    how to trade consistently and join a community of profitable forex traders
                    <Link
                      to={pageLinks.products.url}
                      onClick={() => scrollToTop()}
                    >
                      courses packages!
                    </Link>
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Ticker />
                <div className="icon-box">
                  <i class="bx bx-credit-card"></i>
                  <h4>
                    <a
                      href='https://www.youtube.com/channel/UCGZ595tFkjl4UOH3S9_GQ7Q'
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Youtube Channel
                    </a>
                  </h4>
                  <p>
                    Subscribe to our Youtube for Free Forex Content. Learn Technical Analysis, Psychology
                    of Forex trading and listen to the Trader&apos;s show. {" "}
                    <a
                      href='https://www.youtube.com/channel/UCGZ595tFkjl4UOH3S9_GQ7Q'
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Youtube Channel
                    </a>
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="icon-box">
                  <i class="bx bxs-group"></i>
                  <h4>
                    <a href="https://discord.gg/BsD2Rc59mn" target="_blank" rel="noreferrer">
                      Forex Community
                    </a>
                  </h4>
                  <p>
                    Join our community of Forex Traders. Get updates on the Forex market
                    and               Join our free forex webinars. {" "}
                    <a href="https://discord.gg/BsD2Rc59mn" target="_blank" rel="noreferrer">
                      Discord channel
                    </a>
                  </p>
                </div>
              </div>


              <div
                className="col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="icon-box">
                  <i class="bx bx-happy"></i>
                  <h4>
                    <Link to={`${pageLinks.contact.url}#scheduler`}>
                      Free Induction
                    </Link>
                  </h4>
                  <p>
                    Visit us on our trading floor and learn how to trade Forex profitably.
                    <Link to={`${pageLinks.contact.url}#scheduler`}>
                      {" "}
                      Book a session with us.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Services Section --> */}


        {/* <!-- ======= Pricing Section ======= --> */}
        <PackagesList />
        {/* <!-- End Pricing Section --> */}
        {/* {=========== start of articles section ==========} */}
        <section id="team" className="team section-bg my-3">

          <ArticlesSection />
        </section>
        {/* {=========== end of articles section ==========} */}

        {/* <!-- ======= Cta Section ======= --> */}
        <section id="cta" className="cta">
          <div className="container">
            <div className="row" data-aos="zoom-in">
              <div className="col-lg-9 text-center text-lg-start">
                <h3>Join a Community of Traders</h3>
                <p>
                  {" "}
                  Join our community of traders on discord and enjoy our
                  services. We share trade ideas, offer market updates and share
                  trading nuggets. Enjoy a live trading chatroom and 24/7
                  Support.
                </p>
              </div>
              <div className="col-lg-3 cta-btn-container text-center">
                <a
                  className="cta-btn align-middle"
                  href="https://discord.gg/BsD2Rc59mn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Join Our Discord
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Cta Section --> */}

        {/* <!-- ======= Partners Section ======= --> */}
        <section id="team" className="team section-bg my-3">
          <div className="container" data-aos="fade-up">
            <Partners />
          </div>
        </section>
        {/* <!-- End Partners Section --> */}

        <section className="mt-4 w-100" style={{ display: "flex", flexFlow: "column nowrap", alignItems: "center", justifyContent: "center" }}>
          <a href="https://trk.pepperstonepartners.com/aff_c?offer_id=367&aff_id=29178" target="_blank" rel="noreferrer" >
            <img className="w-100" src={pepperstoneBanner} alt="pepperstone" />
          </a>
          <img src={`https://ad.doubleclick.net/ddm/trackimp/N800570.4342869FINANCIALHUBFX/B24556763.321166893;dc_trk_aid=513450848;dc_trk_cid=161927288;ord=[456654];dc_lat=0;dc_rdid=513450848;tag_for_child_directed_treatment=0;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=?`} border="0" height="100" width="100%" ALT="Advertisement"></img>
        </section>







      </main>
    </>
  );
}
