import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import DefaultPodcastImage from "../../img/default/default-album.jpg";

export default function SchoolCard({ title, description, image, videoId }) {
	const [isOpen, setOpen] = useState(false);
	return (
		<div className="member d-flex align-items-start">
			<ModalVideo
				channel="youtube"
				autoplay
				isOpen={isOpen}
				videoId={videoId}
				onClose={() => setOpen(false)}
			/>
			<div className="book_pic">
				<img
					src={image ? image : DefaultPodcastImage}
					className="img-fluid mb-2"
					alt={title}
				></img>

				<button
					className="btn btn-warning btn-sm text-white"
					onClick={() => setOpen(true)}
				>
					Watch <i className="bx bx-play-circle"></i>
				</button>
			</div>
			<div className="member-info">
				<h4>{title}</h4>
				<span></span>
				<p
					dangerouslySetInnerHTML={{
						__html: `${description.slice(0, 200)}...`,
					}}
				/>
			</div>
		</div>
	);
}
