import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlogSidebar from "../components/BlogSidebar/BlogSidebar";
import Breadcrumb from "../components/Hero/Breadcrumb";
import Loading from "../components/Loading/Loading";
import DefaultImg from "../img/default_user.png";
import { axiosInstance } from "../utils/axios";
import { GetDataLayerStateValue } from "../utils/context";
import PageNotFound from "./PageNotFound";
import { Helmet } from 'react-helmet';

export default function SingleBlogPage() {
  const [singleBlog, setSingleBlog] = useState({
    title: "",
    slug: "",
    content: "",
    image: "",
    created: "",
    user_details: {
      name: "",
      profile_photo: "",
      bio_description: "",
      facebook_link: "",
      twitter_link: "",
      linkedin_link: "",
      instagram: "",
    },
  });
  const [{ isLoading, isError }, dispatch] = GetDataLayerStateValue();

  let { slug } = useParams();

  
  useEffect(() => {
    dispatch({ type: "SET_IS_LOADING", payload: true });
    dispatch({ type: "SET_IS_ERROR", payload: false });

    axiosInstance
      .get(`api/v1/content/article/${slug}/`)
      .then((response) => {
        const data = response.data;
        setSingleBlog(data);

        dispatch({ type: "SET_IS_LOADING", payload: false });
      })
      .catch((error) => {
        dispatch({ type: "SET_IS_LOADING", payload: false });
        dispatch({ type: "SET_IS_ERROR", payload: true });
      });
  }, [dispatch, slug]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <PageNotFound />;
  }
  const date = new Date(singleBlog.created).toDateString();

  return (
    <main id="main">
      <Helmet>
          <meta charSet="utf-8" />
        	<title>{singleBlog.title}</title>
        	<meta name="theme-color" content="#0880e8" />
          <meta property="twitter:title" content={singleBlog.title}/>
          <meta property="twitter:image" itemProp="image"  content={singleBlog.image}/>
          <meta property="twitter:card" content="summary_large_image"/>
     	</Helmet>
      <Breadcrumb page="Articles" title="Article Details" />

      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-8 entries">
              {isLoading ? (
                <Loading />
              ) : (
                <div>
                  <article className="entry entry-single">
                    <div className="entry-img">
                      <img
                        src={singleBlog.image}
                        alt={singleBlog.title}
                        className="img-fluid max-width"
                      />
                    </div>

                    <h2 className="entry-title mt-3">{singleBlog.title}</h2>

                    <div className="entry-meta">
                      <ul>
                        <li className="d-flex align-items-center">
                          <i className="bi bi-clock"></i>{" "}
                          <a href="blog-single.html">
                            <time dateTime={date}>{date}</time>
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="entry-content">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: singleBlog.content,
                        }}
                      />
                    </div>
                    {/* 
										<div className="entry-footer">
											
											<h6>Share this article:</h6>
										<div className="social-links">
											<a href="https://twitters.com/#">
												<i className="bi bi-twitter mr-2"></i>
											</a>
											<a href="https://facebook.com/#">
												<i className="bi bi-facebook mr-2"></i>
											</a>
											<a href="https://instagram.com/#">
												<i className="biu bi-instagram"></i>
											</a>
										</div> 
										</div>
										*/}
                  </article>
                  <div className="team p-1">
                    <h6>About the Author:</h6>
                    <div className="member d-flex align-items-start">
                      <div className="pic">
                        <img
                          src={
                            singleBlog.user_details.profile_photo
                              ? singleBlog.user_details.profile_photo
                              : DefaultImg
                          }
                          className="author-img"
                          alt={singleBlog.user_details.name}
                        ></img>
                      </div>
                      <div className="member-info">
                        <h4>{singleBlog.user_details.name}</h4>
                        <span></span>
                        <p>{singleBlog.user_details.bio_description}</p>
                        <div className="social">
                          {singleBlog.user_details.twitter_link && (
                            <a
                              href={singleBlog.user_details.twitter_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="ri-twitter-fill"></i>
                            </a>
                          )}
                          {singleBlog.user_details.facebook_link && (
                            <a
                              href={singleBlog.user_details.facebook_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="ri-facebook-fill"></i>
                            </a>
                          )}
                          {/* {singleBlog.user_details.instagram && (
														<a
															href={singleBlog.user_details.instagram}
															target="_blank"
														>
															<i className="ri-instagram-fill"></i>
														</a>
													)} */}
                          {singleBlog.user_details.linkedin_link && (
                            <a
                              href={singleBlog.user_details.linkedin_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              <i className="ri-linkedin-box-fill"></i>{" "}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-lg-4">
              <BlogSidebar />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
