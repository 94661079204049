import React, { useState } from "react";
import Breadcrumb from "../components/Hero/Breadcrumb";
import Loading from "../components/Loading/Loading";

export default function CourseDetails() {
  const [tabValue, setTabValue] = useState(0);

  const singleCourse = {
    id: "1",
    image: "course-image.jpg",
    title: "Forex Masterclass Course",
    description: `Prepare for a new career in forex trading. Enroll in the program and get professional forex training designed by Financial Hub. 
    There are over 40 hours of video lectures, articles, tests, and book recommendations designed to help you in your learning journey. The course has over 20 modules covering the three main pillars of trading: System development, risk management, and psychology of trading. Join the Financial Hub community and get an opportunity to be part of the forex trading community!`,
    content: `<p>The Financial Hub Forex Masterclass course has 20 modules as follows:</p>
  
              <h3 class="module-heading">MODULE 1 - GENERAL INTRODUCTION TO TRADING</h3>
              <ul>
                <li>What is Forex Trading</li>
                <li>Explain the concept of Pips</li>
                <li>Forex Terminologies</li>
                <li>Market Sessions</li>
              </ul>
              
              <h3 classs="module-heading">MODULE 2 - FUNDAMENTAL ANALYSIS</h3>
              <ul>
                <li>What is Fundamental Analysis</li>
                <li>How do you use fundamental analysis?</li>
                <li>Fiscal Policy and Monetary Policy</li>
                <li>Fundamental Risks</li>
              </ul>
              
              <h3 class="module-heading">MODULE 3 - FUNDAMENTAL ANALYSIS 2</h3>
              <ul>
                <li>Interest Rates News-Releases</li>
                <li>Geo-political Risks</li>
                <li>Economic Calendar</li>
                <li>Impacts of Fundamentals on Markets</li>
                <li>Historical Examples</li>
              </ul>

              <h3 classs="module-heading">MODULE 4 - TECHNICAL ANALYSIS BASICS</h3>
              <ul>
                <li>Introduction to Technical Analysis</li>
                <li>Concept of a Trend</li>
                <li>Support and Resistance Indicators</li>
                <li>Candlesticks</li>
                <li>Technical Analysis Tools</li>
              </ul>

              <h3 class="module-heading">MODULE 5 - HOW THE FOREX MARKET WORKS</h3>
              <ul>
                <li>Trading Strategies</li>
                <li>Systems Trading Styles</li>
                <li>Different Types of Traders</li>
                <li>Different Kinds of Markets</li>
              </ul>

              <h3 classs="module-heading">MODULE 6 - VALUE AREA TRADING STRATEGY 1</h3>
              <ul>
                <li>Introduction to Pivots</li>
                <li>Anatomy of Pivots</li>
                <li>How do Pivots Work</li>
                <li>In-depth Break-down of Pivots</li>
              </ul>
              
              <h3 class="module-heading">MODULE 7 - VALUE AREA TRADING STRATEGY 2</h3>
              <ul>
                <li>How to use Pivots in Trading</li>
                <li>Top-down Approach in Pivots</li>
                <li>Idea-generation in Pivots</li>
                <li>Back-test and Forward Test</li>
                <li>Limitations of Pivots</li>
              </ul>
              
              <!-- Continue similarly for the rest of the modules -->
              
              
            `,
  
    enrollment: `Enroll anytime! Just purchase the course and start your journey.`,
    your_investment: `
        The total cost for this program is KES 50,000. We do not have any registration fee for this package.
        <h3>How To Pay</h3>
        <ul>
          <li>Payments can be made via <strong>MPESA Paybill Number 562694</strong>.</li>
          <li>We also accept cheques made out to Financial Hub.</li>
          <li>Bank Transfer: (Provide deposit or transfer slip)</li>
          <li>Bank Name: Stanbic Bank</li>
          <li>Account Name: Quantas Financial Hub</li>
          <li>Account Number: 0100006346841</li>
          <li>Branch: Westgate</li>
          <li>Bank Name: KCB Bank</li>
          <li>Account Name: Quantas Financial Hub</li>
          <li>Account Number: 1257176641</li>
          <li>Branch: Kencom</li>
          <li><strong>For International Clients</strong>:</li>
          <li>The cost for the program is $400 paid once.</li>
        </ul>
      `,
  };

  return (
    <main id="main">
      <Breadcrumb page="Courses" title="Course Details" />
      <section id="team" className="team section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>{singleCourse.title}</h2>
          </div>

          <div className="courses-details-main-div">
            <div className="course-details-tab-div">
              <div>
                <a
                  onClick={() => setTabValue(0)}
                  className={`${tabValue === 0 ? "tab-item active" : "tab-item"}`}
                >
                  About the Course
                </a>
                <a
                  onClick={() => setTabValue(1)}
                  className={`${tabValue === 1 ? "tab-item active" : "tab-item"}`}
                >
                  The Modules
                </a>
                <a
                  onClick={() => setTabValue(2)}
                  className={`${tabValue === 2 ? "tab-item active" : "tab-item"}`}
                >
                  Enrollment
                </a>
                <a
                  onClick={() => setTabValue(3)}
                  className={`${tabValue === 3 ? "tab-item active" : "tab-item"}`}
                >
                  Your Investment(Pay Now)
                </a>
             
              </div>
            </div>

            <div className="course-details-content-div">
              {tabValue === 0 && (
                <div className="static-content">
                  <p>{singleCourse.description}</p>
                </div>
              )}
              {tabValue === 1 && (
                 <div className="static-content">
                 <div
                   dangerouslySetInnerHTML={{
                     __html: singleCourse.content,
                   }}
                 />
               </div>
                // <div className="static-content">
                //   <h2>The Modules</h2>
                //   <p>{singleCourse.content}</p>
                // </div>
              )}
              {tabValue === 2 && (
                <div className="static-content">
                  <p>{singleCourse.enrollment}</p>
                </div>
              )}
              {tabValue === 3 && (
                // <div className="static-content">
                //   <h2>Your Investment</h2>
                //   <p>{singleCourse.your_investment}</p>
                // </div>
                <div className="static-content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: singleCourse.your_investment,
                    }}
                  />
                </div>
                )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
