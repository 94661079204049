import React from "react";
import { Link } from "react-router-dom";

export default function PageNotFound() {
	return (
		<section className="error-area text-center align-items-center">
			<div className="section-title">
				<h2>404 ERROR PAGE NOT FOUND</h2>
				<p>
					The requested page could not be found. You can go ahead and view our
					other pages.
				</p>
				<Link to="/" className="btn-get-started mt-4">
					Go to Home Page
				</Link>
			</div>
		</section>
	);
}
