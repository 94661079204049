import React from "react";
import Breadcrumb from "../components/Hero/Breadcrumb";
import FaqPage from "../components/Faq/FaqPage";

export default function FAQs() {
  
  return (
    <main id="main">
      <Breadcrumb page="FAQs" title="FAQs" />
      <section id="faqs" className="container faqs">
        <div>
        <div className="section-title pb-0">
            <h2>FAQs</h2>
          </div>
          <div className="faqs">
            <FaqPage />
          </div>
        </div>
      </section>
    </main>
  );
}
