import React from "react";
import Breadcrumb from "../components/Hero/Breadcrumb";

export default function TrialClass() {
    return (
        <>
            <Breadcrumb page="Testimonials" title="TrialClass" />
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">


                    <div className="row content">
                        

                        <div class="col-md-12 col-12 pt-4 pt-lg-3" data-aos="fade-up">
                            <iframe
                                src="https://docs.google.com/forms/d/e/1FAIpQLSfcIRacWr-Drl6ouYuktK1ZXXHKgjzAe6uy5uKslqCZFCUfGA/viewform?embedded=true"
                                width="100%"
                                height="800"
                                frameBorder="0"
                                marginHeight="0"
                                marginWidth="0"
                                title="Google Form"
                            >
                                Loading…
                            </iframe>

                        </div>

                    </div>

                </div>
            </section>




        </>
    );
}
