import React, { useEffect, useState } from "react";
import { Preloader, ThreeDots } from "react-preloader-icon";
import { InlineWidget } from "react-calendly";
import Breadcrumb from "../components/Hero/Breadcrumb";
import { axiosInstance } from "../utils/axios";
import { GetDataLayerStateValue } from "../utils/context";

export default function ContactPage() {
  const [{ isLoading }, dispatch] = GetDataLayerStateValue();
  const [messageResponse, setMessageResponse] = useState();
  const [message, setMessage] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setMessage({ ...message, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: "SET_IS_LOADING", payload: true });
    if (message.name && message.email && message.subject && message.subject) {
      axiosInstance
        .post("/api/v1/contactus/", message)
        .then(
          setMessageResponse("success"),
          setMessage({
            name: "",
            email: "",
            subject: "",
            message: "",
          })
        )
        .catch(setMessageResponse("error"));
    } else {
      setMessageResponse("error");
    }

    dispatch({ type: "SET_IS_LOADING", payload: false });
  };

  useEffect(() => {
    dispatch({ type: "SET_IS_LOADING", payload: false });
  }, [dispatch]);

  return (
    <main id="main">
      <Breadcrumb page="Contact" title="Contact Us" />
      <section id="faqs" className="container faqs">
      </section>
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Contact Us</h2>
          </div>

          <div
            className="row mt-1 d-flex justify-content-end"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <div className="col-lg-5">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>Lavington Mall, Chandarana Building 4th Floor, Lavington - Nairobi</p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p><a href="mailto:info@financialhubfx.com">info@financialhubfx.com</a> </p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p> +254 757 640 166</p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-5 mt-lg-0">
              <form className="contact-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={message.name}
                      onChange={handleChange}
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={message.email}
                      onChange={handleChange}
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    value={message.subject}
                    onChange={handleChange}
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    value={message.message}
                    onChange={handleChange}
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <div className="align-items-center text-center my-3">
                  {isLoading ? (
                    <Preloader
                      use={ThreeDots}
                      size={60}
                      strokeWidth={6}
                      strokeColor="#05579e"
                      duration={2000}
                    />
                  ) : (
                    <div className="text-center">
                      <button type="submit">Send Message</button>
                    </div>
                  )}
                  <div class="mt-3">
                    {messageResponse === "success" && (
                      <div className="sent-message">
                        Your message has been sent. Thank you!
                      </div>
                    )}
                    {messageResponse === "error" && (
                      <div className="error-message">
                        An error occurred. Please fill out all fields and try
                        again.
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section id="scheduler" className="container">
        <div>
          <div className="section-title pb-0">
            <h2>Schedule a session with us</h2>
          </div>
          {/* <InlineWidget url="https://calendly.com/forex-induction" /> */}
          <InlineWidget url="https://calendly.com/thefinancialhub2/90" />
        </div>
      </section>
    </main>
  );
}
