import React, { useState, useEffect } from "react";
import { TickerTape } from "react-ts-tradingview-widgets";

export default function Ticker() {
	// Define state for dynamic blockSize
	const [blockSize, setBlockSize] = useState("42px");

	// Update blockSize when the window is resized or loaded
	useEffect(() => {
		const updateBlockSize = () => {
			if (window.innerWidth < 600) {
				setBlockSize("69px"); // Change to 62px if screen width < 600px
			} else {
				setBlockSize("42px"); // Default value
			}
		};

		// Add resize event listener
		window.addEventListener("resize", updateBlockSize);

		// Call the function on load to set initial value
		updateBlockSize();

		// Cleanup event listener
		return () => window.removeEventListener("resize", updateBlockSize);
	}, []);

	return (
		<div
			style={{
				height: "61px",
				backgroundColor: "#1D2330",
				overflow: "hidden",
				boxSizing: "border-box",
				border: "1px solid #282E3B",
				textAlign: "right",
				lineHeight: "14px",
				blockSize: blockSize, // Set dynamically from state
				fontSize: "12px",
				fontFeatureSettings: "normal",
				textSizeAdjust: "100%",
				boxShadow: "inset 0 -20px 0 0 #262B38",
				padding: "0px",
				margin: "130px 0 0 0",
				width: "100%",
				position: "fixed",
				zIndex: "99",
			}}
		>
			<div style={{ height: "36px", padding: "0px", margin: "0px", width: "100%" }}>
				<TickerTape showSymbolLogo={true}></TickerTape>
			</div>
			<div
				style={{
					color: "#626B7F",
					lineHeight: "14px",
					fontWeight: 400,
					fontSize: "11px",
					boxSizing: "border-box",
					padding: "2px 6px",
					width: "100%",
					fontFamily: "Verdana, Tahoma, Arial, sans-serif",
				}}
			></div>
		</div>
	);
}
