import React from "react";
import TeamCard from "./TeamCard";
import TeamMemberImg from "../../img/team/ken.webp";
import TeamMemberImgTwo from "../../img/team/caleb.webp";
// import TeamMemberImgFour from "../../img/team/team-4.jpg";
// import whitney from "../../img/team/whitney.webp";
// import wanderi from "../../img/team/wanderi.webp";
// import douglas from "../../img/team/douglas.webp";

const teamMembers = [
  {
    name: "Kenneth M",
    title: "Fund Manager/Founder",
    quote: `Ken is a founder, fund manager, and trader at Financial hub. He has a background in 
    project management from the professional google courses. 
    At Financial hub, he is focused on trading currencies, commodities, and indices mainly. 
    His responsibilities include; mentoring students, managing the fund, and running the 
    company. Ken is an avid reader who is passionate about learning and building 
    companies. 
    His philosophy revolves around creating opportunities in the African market by 
    developing professional forex traders. He has authored a book titled "A Template For Understanding The Forex Market."`,
    image: TeamMemberImg,
    linkedIn: "https://www.linkedin.com/in/Ken-Munene",
  },
  {
    name: "Caleb Sam",
    title: "Director/Founder",
    quote:
      "Caleb is a Founder, Mentor, Trader and a Creative Content Creator at Financial Hub.His preferable ways of trading involves deep and long term technical analysis, which he has perfected over the years to create an edge for himself.Alongside his co-founders, he also runs the company operations on a day to day basis, with the aim of constantly improving their products so that their clients can get top of the class value.He believes in Ray Dalio’s Philosophy on company culture. His ideas on the culture of radical truthfulness and transparency in an idea meritocratic way, which aims to find the best solutions while tackling problems",
    image: TeamMemberImgTwo,
    linkedIn: "https://www.linkedin.com/in/caleb-sam-05187012b",
  }
  // {
  //   name: "Harman Kibue",
  //   title: "External Software Consultant",
  //   quote:
  //     "Harman Kibue is a seasoned software engineer with background in Electrical and electronics engineering(computer option) from The  Technical University Of Kenya.Harman has worked at several tech companies and currently an Andelan.At Financial Hub he is involved in overseeing and leading the design, implementation, automation and scaling already existing financial software solutions.",
  //   image: TeamMemberImgFour,
  //   linkedIn: "https://www.linkedin.com/in/harmann-kibue",
  // },

  // {
  //   name: "Whitney Akinyi",
  //   title: "Head of Marketing & Communications",
  //   quote:
  //     "Whitney leads marketing and communications at Financial Hub, bringing strategic vision and creative expertise to elevate our presence in financial education. With a strong focus on branding and digital strategy, Whitney drives impactful campaigns and fosters effective communication both internally and externally.Her leadership inspires collaboration and innovation, shaping our company's success in empowering individuals through financial education.",
  //   image: whitney,
  //   linkedIn: "#0",
  // },
  // {
  //   name: "Wanderi Kariuki",
  //   title: "Sales Team Lead",
  //   quote:
  //     "Wanderi is the heartbeat of our sales team at Financial Hub. With a keen understanding of our financial literacy courses and an unwavering commitment to client satisfaction, Wanderi brings a dynamic energy to every interaction. Whether he’s engaging with potential clients online, guiding them through our offerings, or providing insightful advice, Wanderi is passionate about helping others achieve success through financial education. His dedication and expertise make him an invaluable asset to our team.",
  //   image: wanderi,
  //   linkedIn: "#0",
  // },
  // {
  //   name: "Douglas Ochola",
  //   title: "Head of Visual Content",
  //   quote:
  //     "At Financial Hub, our video production is led by Douglas, an expert with almost a decade of experience in creating high-quality educational content. Douglas is dedicated to producing engaging and informative videos that cater to our community of traders, making complex Forex trading concepts accessible and easy to understand.Under his leadership, our video production team has become a cornerstone of our educational outreach, consistently delivering top- notch tutorials, webinars, and market analysis that empower our members with the knowledge and skills they need for success.",
  //   image: douglas,
  //   linkedIn: "#0",
  // },

];

export default function TeamList() {
  return (
    <div className="row">
      {teamMembers.map((member) => {
        const { name, title, quote, image, linkedIn } = member;
        return (
          <TeamCard
            key={name}
            name={name}
            title={title}
            quote={quote}
            image={image}
            linkedIn={linkedIn}
          />
        );
      })}
    </div>
  );
}
