import React, {useState} from "react";
import AccordionItem from "./AccordionItem";

const faqs = [
  {
    index: 0,
    question: "Where are your offices located ?",
    answer:
      "We are located at Lavington Mall, Chandarana Building 3rd Floor, Lavington - Nairobi <a href='https://www.google.com/maps/dir//Financial+Hub+Forex+Academy/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x182f112e542004c3:0xf3ecc2491fafc516?sa=X&hl=en&ved=2ahUKEwiR_M-Xn_f8AhXuaqQEHVD7DbkQ9Rd6BAhXEAU' target='_blank' rel='noreferrer'>https://www.google.com/maps/dir//Financial+Hub+Forex+Academy/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x182f112e542004c3:0xf3ecc2491fafc516?sa=X&hl=en&ved=2ahUKEwiR_M-Xn_f8AhXuaqQEHVD7DbkQ9Rd6BAhXEAU</a>",
  },
  {
    index: 1,
    question: "Do you offer both physical and virtual classes?",
    answer:
      "Yes we offer both physical classes at our offices and virtual classes for those who are not available for physical classes.",
  },
  {
    index: 2,
    question: "How much are your courses?",
    answer:
      "We have a beginner online course which goes for Ksh.10,000 for a lifetime access. Our one month course (Masterclass is 20,000) and our two month course Legendary course is 40,000. More details here. <a href='https://www.financialhubfx.com/products' target='_blank' rel='noreferrer'>https://www.financialhubfx.com/courses</a>",
  },
  {
    index: 3,
    question: "Can I pay in installments? ",
    answer:
      "Yes you can pay in monthly installments for the Legendary course.",
  },
  {
    index: 4,
    question: "What broker do you use?",
    answer:
      "We use Pepperstone broker, a broker regulated and licensed by CMA. Sign up here <a href='https://trk.pepperstonepartners.com/aff_c?offer_id=367&aff_id=29178' target='_blank' rel='noreferrer'>https://trk.pepperstonepartners.com/aff_c?offer_id=367&aff_id=29178</a>",
  },
  {
    index: 5,
    question: "Do you offer signals?",
    answer:
      "No we do not offer signals instead we offer zoom sessions every Monday Wednesday and Friday to break down markets and share trading nuggets. Join our Discord to get links promptly to the zoom sessions. <a href='https://discord.gg/6NUEdRGwdK' target='_blank' rel='noreferrer'>https://discord.gg/6NUEdRGwdK</a>",
  },
  {
    index: 6,
    question: "How much is your book and can it be delivered?",
    answer:
      "Our book retails for Ksh. 1500 and country wide deliveries can be done at your cost.",
  },
  {
    index: 7,
    question: "What is the difference between the masterclass & legendary course.",
    answer:
      "The masterclass is a one month course curated for a trader who has some basic experience but struggling with maybe system development, risk management or psychology. It has 8 sessions of 2-hour. It costs Ksh. 20,000.FAQs2The Legendary course is a two month course curated for a beginner trader. It has 16 modules and will allow a new trader to move from a beginner to advanced level. It costs Ksh. 40,000.",
  },
  {
    index: 8,
    question: "Do you trade on behalf of people? ",
    answer:
      "No!We do not take part in the Money management business.",
  },
  {
    index: 9,
    question: "Can I have online zoom sessions for my classes ?",
    answer:
      "Yes. You can do online zoom sessions. We have trained people from across the globe.Training people from outside Nairobi is also part of our programs.",
  }
];

const FaqPage = () => {
  const [clicked, setClicked] = useState("0");

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0");
    }
    setClicked(index);
  };

  return (
    <ul className="accordion">
      {faqs.map((faq, index) => (
        <AccordionItem
          onToggle={() => handleToggle(index)}
          active={clicked === index}
          key={index}
          faq={faq}
        />
      ))}
    </ul>
  );
};

export default FaqPage;
