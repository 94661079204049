export const initialState = {
	client_id: "OmnrX0pBDacXhoov6KPjd82YXdPRtDAQh6ThUfiM",
	// This is mine for local host
	// client_id: "Ht9LY6F1vbSAvaU8DflWiGuDg0wtqvGYknxbCm5I",
	username: "",
	email: "",
	email2: "",
	password: "",
	password2: "",
	alertClass: "",
	alertMessage: "",
	showAlertMessage: false,
	isLoading: true,
	isError: false,
	courses: [],
	nextUrl: "",
	previousUrl: "",
	articlesUrl: "/api/v1/content/article/",
	articles: [],
	playingPodcast: "",
};

const reducer = (state, action) => {
	console.log(action.type);
	switch (action.type) {
		case "SET_CREDENTIALS":
			return {
				...state,
				[Object.keys(action.value)[0]]: Object.values(action.value)[0],
			};
		case "CLEAR_CREDENTIALS":
			return {
				...state,
				username: "",
				email: "",
				email2: "",
				password: "",
				password2: "",
			};
		case "SHOW_ALERT_MESSAGE":
			return {
				...state,
				alertClass: action.alertClass,
				alertMessage: action.alertMessage,
				showAlertMessage: true,
			};
		case "CLEAR_ALERT_MESSAGE":
			return {
				...state,
				// alertClass: "",
				// alertMessage: "",
				showAlertMessage: false,
			};
		case "SET_IS_LOADING":
			return {
				...state,
				isLoading: action.payload,
			};
		case "SET_IS_ERROR":
			return {
				...state,
				isError: action.payload,
			};
		case "SET_COURSES":
			return {
				...state,
				courses: action.payload,
				previousUrl: action.previousUrl,
				nextUrl: action.nextUrl,
			};
		case "SET_PLAYING_PODCAST":
			return {
				...state,
				playingPodcast: action.payload,
			};
		case "SET_ARTICLES_URL":
			return {
				...state,
				articlesUrl: action.payload,
			};
		case "SET_ARTICLES":
			return {
				...state,
				articles: action.payload,
				previousUrl: action.previousUrl,
				nextUrl: action.nextUrl,
			};
		default:
			return new Error(
				`Bomboclaat yute, yuh nuh match this action >>>> ${action}`
			);
	}
};

export default reducer;
