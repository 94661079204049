export const scrollToTop = () => {
	// sroll to the top of the page
	window.scrollTo(0, 0);
};

export const isUserAuthenticated = () => {
	const accessToken = localStorage.getItem("access_token");
	const refreshToken = localStorage.getItem("access_token");
	if (accessToken && refreshToken) {
		return true;
	} else {
		return false;
	}
};
