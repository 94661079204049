import React from "react";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/utils";

export default function ArticlesCard({
	id,
	slug,
	title,
	image,
	description,
	created,
}) {
	return (
		<article className="entry row">
			<div className="col-md-4 col-12">
				<img src={image} alt={title} className="img-fluid mb-2" />
			</div>
			<div className="col-md-8 col-12">
				<h2 className="entry-list-title">
					<Link to={`/article/${slug}`} onClick={() => scrollToTop()}>
						{title}
					</Link>
				</h2>

				<div className="entry-meta">
					<ul>
						<li className="d-flex align-items-center">
							<i className="bi bi-clock"></i>{" "}
							<Link to={`/article/${slug}`} onClick={() => scrollToTop}>
								<time dateTime={created}>{created}</time>
							</Link>
						</li>
					</ul>
				</div>

				<div className="entry-content-list font-15">
					{description && (
						<p
							dangerouslySetInnerHTML={{
								__html: `${description.slice(0, 150)}...`,
							}}
						/>
					)}
					<div className="read-more">
						<Link to={`/article/${slug}`} onClick={() => scrollToTop()}>
							Read More
						</Link>
					</div>
				</div>
			</div>
		</article>
	);
}
