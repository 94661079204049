import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import styled from "styled-components";
import { isUserAuthenticated, scrollToTop } from "../../utils/utils";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import pageLinks from "../../utils/links";
// import DefaultPodcastImage from "../img/default/default-album.jpg";

export default function BooksCard({
  image,
  title,
  price,
  description,
  isFree,
  downloadFile,
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isAuthenticated = isUserAuthenticated();
  let UPPER_TEXT_LIMIT = 125;
  if(window.screen.width < 500){UPPER_TEXT_LIMIT = 100}
  return (
    <MainCardDiv>
      <img src={image} alt={title}></img>
      <div className="member-info">
        <h4 className="entry-title">{title}</h4>
        <span>{price}</span>
        {description && (
          <p
            dangerouslySetInnerHTML={{
              __html: `${description.slice(0, UPPER_TEXT_LIMIT)}...`,
            }}
          />
        )}
        {isFree ? (
          isAuthenticated ? (
            <a
              href={downloadFile}
              className="btn btn-warning btn-sm text-white"
            >
              <BookButton>Download</BookButton>
            </a>
          ) : (
            <BookButton onClick={handleShow}>Download</BookButton>
          )
        ) : (
          <a
            href={`https://wa.me/+254769001272/?text=Hi, I'm interested in the book ${title}, how can I get it?`}
          >
            <BookButton>Buy now</BookButton>
          </a>
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login to Download</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please login to download the free e-book{" "}
          <Link
            to={pageLinks.login.url}
            className="btn btn-warning btn-sm text-white"
            onClick={() => {handleClose(); scrollToTop()}}
          >
            Login Here
          </Link>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </MainCardDiv>
  );
}

const MainCardDiv = styled.div`
  height: 20em;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 2rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  > img {
    width: auto;
    height: 100%;
    border-radius: 5px 0px 0px 5px;

	@media (max-width: 768px) {
		width: 50%;
		height: 100%;
	}
  }
  > div { 
	  padding: 1em;
  }

  @media (max-width: 768px) {
	  height: 20em;
	  width: 100%;

	  > div {
		  > h4 {font-size: 1.1em; font-weight: 500;}
		  padding: 0.5em;
	  }
  }
`;

const BookButton = styled.button`
  height: 2em;
  width: 10em;
  border-radius: 5px;
  background-color: #f6b024;
  border: none;
  color: white;
  font-size: 1em;
  font-weight: 400;
  margin-left: 1em;
  margin-bottom: 1em;
  margin-top: 3em;
  cursor: pointer;
  &:hover {
    background-color: #000;
    color: #f6b024;
  }

  @media (max-width: 768px) {
	  width: auto;
	  padding: 0 1.5em 0 1.5em;
  }
`;