const pageLinks = {
	login: { name: "Sign In", url: "/login/" },
	signup: { name: "Sign Up", url: "/signup/" },
	resetPassword: { name: "Forgot Password?", url: "/reset-password/" },
	changePassword: { name: "Change Password", url: "/change-password/" },
	home: { name: "Home", url: "/" },
	products: { name: "Forex Courses", url: "/products/" },
	courses: { name: "Forex Courses", url: "/courses/" },
	funding: { name: "Funding Program", url: "/funding-program/" },
	books: { name: "Books", url: "/books/" },
	podcasts: { name: "Podcasts", url: "/podcasts/" },
	educationalArticles: {
		name: "Educational",
		url: "/articles/educational/",
	},
	psychologyArticles: {
		name: "Psychology",
		url: "/articles/psychology/"
	},
	
	cryptoArticles: {
		name: "Crypto",
		url: "/articles/crypto/"
	},
	school: { name: "Free Forex School", url: "/school/" },
	
	about: { name: "About", url: "/about/" },
	
	inthespotlight:
	{
		name: "In The Spotlight",
		url: "/spotlight/"
	},
	testimonials:
	{
		name: "Testimonials",
		url: "/testimonials/"
	},

	trialclass:
	{
		name: "Trial Class",
		url: "/trialclass/"
	},

	contact: { name: "Contact", url: "/contact/" },
	// portal: { name: "Portal", url: "https://sso.teachable.com/secure/390752/identity/login" },
};

export default pageLinks;
