import React, { useState } from "react";
import "../fonts/font-awesome-4.7.0/css/font-awesome.css";
import { Link } from "react-router-dom";
import pageLinks from "../utils/links";
import { scrollToTop } from "../utils/utils";
import {
  EmailField,
  NameField,
  PasswordField,
} from "../components/Fields/Fields";
import { GetDataLayerStateValue } from "../utils/context";
import { axiosInstance } from "../utils/axios";
import AlertMessage from "../components/Messages/Messages";
import { AuthLoader } from "../components/Loading/Loading";

const SignUp = () => {
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [
    { username, email, email2, password, password2, showAlertMessage },
    dispatch,
  ] = GetDataLayerStateValue();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsAuthLoading(true);
    // validate the form and submit
    if (username && email && email2 && password && password2) {
      if (email === email2 && password === password2) {
        axiosInstance
          .post("/api/v1/users/register/", { name: username, email, password })
          .then((response) => {
            console.log("The status is >>>>", response.status);
            console.log("The response after signup >>>", response.data);
            setIsAuthLoading(false);
            window.location.href = "/login/";
            dispatch({ type: "CLEAR_CREDENTIALS" });
            dispatch({
              type: "SHOW_ALERT_MESSAGE",
              alertClass: "success",
              alertMessage: "Account created successfully, you can now login",
            });
          })
          .catch((error) => {
            console.log("The signup error is >>>>", error);
            setIsAuthLoading(false);
          });
        setIsAuthLoading(false);
      } else if (email !== email2 && password !== password2) {
        dispatch({
          type: "SHOW_ALERT_MESSAGE",
          alertClass: "danger",
          alertMessage: "Both emails and passwords did not match",
        });
      } else if (email !== email2) {
        dispatch({
          type: "SHOW_ALERT_MESSAGE",
          alertClass: "danger",
          alertMessage: "Emails did not match",
        });
      } else if (password !== password2) {
        dispatch({
          type: "SHOW_ALERT_MESSAGE",
          alertClass: "danger",
          alertMessage: "Passwords did not match",
        });
      } else {
        dispatch({
          type: "SHOW_ALERT_MESSAGE",
          alertClass: "danger",
          alertMessage: "Please fill all fields",
        });
      }
    }
  };
  return (
    <main>
      <section id="icon-boxes" className="icon-boxes login-area">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6 align-items-stretch mb-5">
              <div className="icon-box mt-5">
                <form className="login-form" onSubmit={handleSubmit}>
                  <h5 className="text-center mb-3">Sign Up</h5>
                  <NameField />
                  <EmailField name="email" />
                  <EmailField name="email2" />
                  <PasswordField name="password" />
                  <PasswordField name="password2" />

                  <div className="text-center mt-4">
                    <button className="btn btn-primary" type="submit">
                      {isAuthLoading ? <AuthLoader /> : "Register"}
                    </button>
                  </div>
                </form>

                {showAlertMessage && <AlertMessage />}

                <p className="text-center mt-2">
                  Already have an account?{" "}
                  <Link to={pageLinks.login.url} onClick={() => scrollToTop}>
                    Login here
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SignUp;
