import React from "react";
import Breadcrumb from "../components/Hero/Breadcrumb";
import CoursesPage from "./Course";
import BooksPage from "./Books";

export default function ProductsPage() {
	return (
		<main id="main">
			<Breadcrumb page="Products " title="Products" />
			<CoursesPage />
		    <BooksPage />
		</main>
	);
}
