import React from "react";
import { Link } from "react-router-dom";
import SidebarSearch from "../BlogSidebar/SidebarSearch";

export default function Breadcrumb({ page, title, pageUrl }) {
	return (
		<section id="breadcrumbs" className="breadcrumbs">
			<div className="container">
				<ol>
					<li>
						<Link to="/">Home</Link>
					</li>
					<li>
						<Link to={pageUrl ? pageUrl : "#"}>{page}</Link>
					</li>
				</ol>
				<h2>{title}</h2>
				{title === "Articles" && <SidebarSearch />}
			</div>
		</section>
	);
}
