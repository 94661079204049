import React from "react";
import Breadcrumb from "../components/Hero/Breadcrumb";
import TeamList from "../components/Team/TeamList";
import ourvaluesbanner from "../img/fhub/our-values.jpg"

export default function AboutPage() {
  return (
    <>
      <Breadcrumb page="About" title="About Us" />
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>About Us</h2>
          </div>

          <div className="row content">
            <div className="col-md-12 col-12 pt-4 pt-lg-0">
              
              <p>
                <a href="https://www.financialhubfx.com/">Financial Hub</a> was founded in 2017 by two teens in a
                single room apartment. We were passionate and ready
                to transform our own lives through understanding the
                financial markets, however, we did not know how or
                where to start.
              </p>

              <p>
                That is when Financial Hub was born. We struggled for
                months to gain access to a credible institutions that
                offered education on the financial markets until we
                decided to go through the internet (as hard as it was
                living in a 3rd world country) and figure out how we
                would educate ourselves.
              </p>

              <p>
                What would have possibly taken 2-4 years to learn has
                taken us 7 years. 7 years of research, reading, watching
                global news and testing our ideologies.
              </p>

              
              <button className="student-portal-button"><a href="/faqs">See our FAQs</a></button>

            </div>

            <div class="col-md-6 col-12 pt-4 pt-lg-0"> 
              
              <div className="section-title">
                <h2>Mission</h2> 
              </div>
              <p>
                Our mission is to educate and equip the youth of Africa with
                comprehensive financial market trading knowledge and skills.
                By providing accessible and effective training, we aim to
                improve their economic prospects, inspire a sense of hope and
                possibility, and instill a lifelong commitment to personal and
                community development. Through our efforts, we strive to
                cultivate a generation of financially literate, responsible, and
                empowered individuals who can transform their lives and
                contribute positively to society.
              </p>
              </div>

            <div class="col-md-6 col-12 pt-4 pt-lg-0"> 
              <div className="section-title">
                <h2>Vision</h2>  
              </div>
              <p>
                To educate and empower over 1 million youth of Africa,
                revolutionizing their financial acumen and skills to
                build a prosperous and resilient future for themselves
                and their communities, fostering a wave of sustainable
                societal change.
              </p>
            </div>


            <div class="col-md-12">
              <div className="section-title">
                <h2>Our Values</h2>
                <p> At Financial Hub, our values are the foundation of our success: </p>
              </div>
            </div>

            <div class="col-md-6 col-12 pt-4 pt-lg-0">
              <ul>
                <li> <b> T- Teamwork: </b> We believe that collaboration and unity are key to
                  achieving our goals. Together, we can accomplish more. </li>
                <li> <b> R- Readiness to Help: </b> Our commitment to support each other
                  and our community is unwavering. We are always prepared to
                  lend a hand. </li>
                
                <li> <b> A- Agility: </b> In a fast-paced world, our ability to adapt quickly and
                  efficiently is crucial. We embrace change and navigate challenges
                with ease. </li>



                <li> <b> D- Discipline & Dedication: </b> We approach our work with
                  unwavering focus and determination. Our disciplined approach
                ensures consistent, high-quality results </li>



                <li> <b> E- Excellence: </b> We strive for the highest standards in everything
                  we do. Our pursuit of excellence drives us to continually improve
                and innovate. </li>

              </ul>

            </div>


            <div class="col-md-6 col-12 pt-4 pt-lg-0">
              <img width={"100%"} height={"auto"} src={ourvaluesbanner} alt="client 1" />
            </div>


            <div class="col-md-12">
              <div className="section-title">
                <p>These values guide our actions and decisions, shaping a culture of
                  integrity, respect, and continuous growth.</p>
              </div>
            </div>

          </div>

        </div>
      </section>


      <section id="team" className="team section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Our Team</h2>
          </div>
          <TeamList />
        </div>
      </section>

    </>
  );
}
