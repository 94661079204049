import React from "react";
import DefaultPodcastImage from "../../img/default/default-album.jpg";
import { GetDataLayerStateValue } from "../../utils/context";

export default function PodcastCard({ id, image, title, description, audio }) {
	const [, dispatch] = GetDataLayerStateValue();
	const setPlayingPodcast = () => {
		dispatch({ type: "SET_PLAYING_PODCAST", payload: audio });
	};
	return (
		<div className="member d-flex align-items-start">
			<div className="podcast_pic">
				<img
					src={image ? image : DefaultPodcastImage}
					className="img-fluid mb-2"
					alt={title}
				></img>
			</div>
			<div className="member-info">
				<h4>{title}</h4>
				<p className="podcast-desc">
				{description && (
					<p
						dangerouslySetInnerHTML={{
							__html: `${description.slice(0, 150)}...`,
						}}
					/>
				)}
				</p>
				<p className="podcast-desc-long">
				{description && (
					<p
					dangerouslySetInnerHTML={{
						__html: `${description}`,
					}}
				/>
				)}
				</p>
				<span className="mb-1"></span>
				<div className="player" onClick={() => setPlayingPodcast()}>
					<i className="bx bx-play-circle"></i>
				</div>
			</div>
		</div>
	);
}
