import "./App.css";
import { Switch, Route } from "react-router-dom";
import HomePage from "./pages/Home";
import AboutPage from "./pages/About";

// added section
import CoursePage from "./pages/Course";
import BlogPage from "./pages/Blog";
import SingleBlogPage from "./pages/SingleBlog";
import ContactPage from "./pages/Contact";
import CoursesPage from "./pages/Course";
import BooksPage from "./pages/Books";
import PageNotFound from "./pages/PageNotFound";
import SignUpPage from "./pages/SignUp";
import SignInPage from "./pages/SignIn";
import SchoolPage from "./pages/School";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import WhatsAppChatFloat from "./components/ChatFloat/WhatsAppChatFloat";
import PodcastsPage from "./pages/Podcasts";
import PodcastPlayer from "./components/PodcastPlayer/PodcastPlayer";
import ResetPassword from "./pages/ResetPassword";
import { GetDataLayerStateValue } from "./utils/context";
import FundingPage from "./pages/Funding";
import ProductsPage from "./pages/Products";
import CourseDetails from "./pages/CourseDetails";
import FAQs from "./pages/FAQS";
import { Helmet } from 'react-helmet';
import Spotlight from "./pages/Spotlight";
import Testimonials from "./pages/Testimonials";
import TrialClass from "./pages/TrialClass";

// import ReactGA from "react-ga";
// var host = window.location.hostname;
// if (host === "localhost") {
//   ReactGA.initialize("G-T0DYNDSNXJ");
//   ReactGA.pageview(window.location.pathname + window.location.search);
// }

const App = () => {
	const [{ playingPodcast }] = GetDataLayerStateValue();
	// useGaTracker();

	return (
		<>
			<Helmet>
        		{/* <title>Financial Hub Forex Academy</title> */}

				{/* <meta keywords = "Forex Trading, Forex trade for beginners, Forex Academy,forex trading profit, trading fx, Forex, Forex Club, Financial hub, Forex market, Forex world, what is forex, forex broker"/> */}
        

				{/* <meta name="description" content="Financial Hub is the #1 site for forex traders to learn and exchange knowledge and wisdom. We provide training/mentorship and guidance from beginner to  advanced forex training, information on forex trading, reviews of the best forex brokers and training tools" /> */}
				

				{/* <!--  Meta Tags --> */}
				{/* <meta property="og:url" content="https://www.financialhubfx.com/"/>
				<meta property="og:type" content="website"/>
				<meta property="og:title" content="Financial Hub : Forex Academy"/>
				<meta property="og:description" content="Financial hub is a company that is focused on training, funding, and mentoring forex traders. The company was started in 2018 by three young, passionate forex traders out of a two-bedroom apartment in Nairobi."/>
				<meta property="og:image" content="https://www.financialhubfx.com/static/media/logo.e067eed1.png"/> */}
				
				
				
				{/* <!--  Meta Tags --> */}
				{/* <meta name="twitter:card" content="summary_large_image"/>
				<meta property="twitter:domain" content="financialhubfx.com"/>
				<meta property="twitter:url" content="https://www.financialhubfx.com/"/>
				<meta name="twitter:title" content="Financial Hub : Forex Academy"/>
				<meta name="twitter:description" content="Financial hub is a company that is focused on training, funding, and mentoring forex traders. The company was started in 2018 by three young, passionate forex traders out of a two-bedroom apartment in Nairobi."/>
				<meta name="twitter:image" content="https://www.financialhubfx.com/static/media/logo.e067eed1.png"/> */}



			
		
     		 </Helmet>
			<Navbar />
			<Switch>
				<Route exact path="/">
					<HomePage />
				</Route>
				<Route exact path="/about/">
					<AboutPage />
				</Route>

				{/* added section */}
				<Route exact path="/course/">
					<CoursePage />
				</Route>
				<Route exact path="/signup/">
					<SignUpPage />
				</Route>
				<Route exact path="/login/">
					<SignInPage />
				</Route>
				<Route exact path="/reset-password/">
					<ResetPassword />
				</Route>
				<Route exact path="/contact/">
					<ContactPage />
				</Route>
				<Route exact path="/articles/:category/">
					<BlogPage />
				</Route>
				<Route exact path="/article/:slug">
					<SingleBlogPage />
				</Route>
				<Route exact path="/courses/">
					<CoursesPage />
				</Route>
				<Route exact path="/funding-program/">
					<FundingPage />
				</Route>
				<Route exact path="/courses/:slug">
					<CourseDetails />
				</Route>
				<Route exact path="/podcasts/">
					<PodcastsPage />
				</Route>
				<Route exact path="/books/">
					<BooksPage />
				</Route>
				<Route exact path="/school/">
					<SchoolPage />
				</Route>
				<Route exact path="/products/">
					<ProductsPage />
				</Route>
				<Route exact path="/faqs/">
					<FAQs />
				</Route>
				<Route exact path="/spotlight/">
					<Spotlight />
				</Route>

				<Route exact path="/testimonials/">
					<Testimonials />
				</Route>

				<Route exact path="/trialclass/">
					<TrialClass />
				</Route>
				
				
				<Route>
					<PageNotFound />
				</Route>
			</Switch>
			<Footer />
			{playingPodcast && <PodcastPlayer audio={playingPodcast} />}
			<WhatsAppChatFloat/>
		</>
	);
}

export default App;
