import React from "react";
import { Preloader, Puff, Oval } from "react-preloader-icon";

export function AuthLoader() {
  return (
    <Preloader
      use={Oval}
      size={30}
      strokeWidth={10}
      strokeColor="#fff"
      duration={2000}
    />
  );
}

export default function Loading() {
  return (
    <section className="loading-area text-center align-items-center">
      <Preloader
        className="text-center align-items-center"
        use={Puff}
        size={60}
        strokeWidth={6}
        strokeColor="#05579e"
        duration={2100}
      />
    </section>
  );
}
