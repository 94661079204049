import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./vendor/bootstrap-icons/bootstrap-icons.css";
import "./vendor/boxicons/css/boxicons.css";
import "./vendor/remixicon/remixicon.css";
import AOS from "aos";
import "aos/dist/aos.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { DataLayer } from "./utils/context";
import reducer, { initialState } from "./utils/reducer";

ReactDOM.hydrate(
	<React.StrictMode>
		<Router>
			<DataLayer initialState={initialState} reducer={reducer}>
				<App />
			</DataLayer>
		</Router>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
AOS.init({
	disable: window.innerWidth < 768,
});
