import React from "react";
import { Link } from "react-router-dom";
import defaultImg from "../../img/logo.png";

export default function SidebarNewsCard({ title, date, image, slug }) {
  return (
    <div className="post-item clearfix">
      <img src={image ? image : defaultImg} alt={title} />
      <h4>
        <Link to={`/article/${slug}`}>{title}</Link>
      </h4>
      <time dateTime={date}>{date}</time>
    </div>
  );
}
