import React, { useEffect } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./player.css";

export default function PodcastPlayer({ audio }) {
	useEffect(() => {}, [audio]);
	return (
		<div className="player_area">
			<AudioPlayer
				autoPlay
				src={audio}
				// onPlay={(e) => console.log("onPlay")}
				// other props here
			/>
		</div>
	);
}
