import React, { useCallback, useEffect, useState } from "react";
import { axiosInstance } from "../../utils/axios";
import { GetDataLayerStateValue } from "../../utils/context";
import Loading from "../Loading/Loading";
import PackageCard from "./PackageCard";
import { Link } from "react-router-dom";
import pageLinks from "../../utils/links";
import { scrollToTop } from "../../utils/utils";
import enrolBanner from "../../img/fhub/slider-reception.webp"

export default function PackagesList() {
	const [{ isLoading }, dispatch] = GetDataLayerStateValue();
	const [packages, setPackages] = useState([]);

	const getPackages = useCallback(() => {
		dispatch({ type: "SET_IS_LOADING", payload: true });
		dispatch({ type: "SET_IS_ERROR", payload: false });

		axiosInstance
			.get("/api/v1/product/packages/")
			.then((response) => {
				const data = response.data;
				setPackages(data.results);
				console.log(data.results[0]);
				dispatch({ type: "SET_IS_LOADING", payload: false });
			})
			.catch((error) => {
				console.log("ERROR WHEN FETCHING PACKAGES...", error.response);
			});
	}, [dispatch]);

	useEffect(() => {
		getPackages();
	}, [getPackages]);

	if (isLoading) {
		return <Loading />;
	}
	return (
		<section id="pricing" className="pricing mt-5">
			<div className="container" data-aos="fade-up">

				<div className="row content">

					<div class="col-md-6 col-12 pt-4 pt-lg-3" data-aos="fade-up">
						<img width={"100%"} height={"auto"} src={enrolBanner} alt="Media Banner" />
					</div>

					<div class="col-md-6 col-12 pt-4 pt-lg-3" data-aos="fade-up">

						<div className="section-title">
							<h2>Our Courses Package</h2>
							<p>
								Prepare for a new career in forex trading. Enroll to the program and get professional forex training designed by financial hub. There are over 40 hours of video lectures, articles, tests and book reccomendations designed to help you in your learning journey. The course has over 20 modules covering the three main pillars of trading: System development, risk management and psychology of trading. Join the Financial hub community and get an opportunity to be part of the forex trading community!
								{/* The three programs listed below have been created and curated by our team of experts.Learn how to trade like a pro!  */}
							</p>
							<div className="">
								<h4>
									<sup>Ksh. 50,000</sup>

								</h4>
								<span className="text-primary"></span>
							</div>
							<div>
								<Link
									to='/course/'
									className="btn-buy"
									onClick={() => scrollToTop()}
									style={{ marginRight: '10px' }}
								>
									Enroll Now
								</Link>

								{/* <Link
									to='/course/'
									className="btn-buy"
									onClick={() => scrollToTop()}
								>
									Download Our E-Book
								</Link> */}

									<Link
										to='#'
										className="btn-buy"
										onClick={() => {
											scrollToTop();
											alert('Your E-Book is ready for download!');
										}}
									>
										Download Our E-Book
									</Link>

							</div>
						</div>

					</div>


				</div>

			</div>
		</section>
	);
}
