import React from "react";
import Breadcrumb from "../components/Hero/Breadcrumb";
import mediabanner from "../img/fhub/banner-media.jpg"

export default function Spotlight() {
    return (
        <>
            <Breadcrumb page="Spotlight" title="In The Spotlight" />
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">
                   

                    <div className="row content">
                        <div className="col-md-12 col-12 pt-4 pt-lg-0">


                            <div className="section-title">
                                <p>Where We Have Been Featured</p>
                            </div>



                            <div className="section-title">
                                <h2>Nation TV</h2>
                            </div>


                            <p>
                                We were featured on NTV TECH NEWS, where our cutting-edge
                                approach to Forex trading education was highlighted. The feature
                                focused on our commitment to leveraging the latest technological
                                advancements to enhance trading strategies and risk management.
                                This recognition by NTV TECH NEWS underscores our dedication to
                                providing traders with the tools and insights necessary for navigating
                                the ever-evolving Forex market successfully.
                            </p>

                    
                        </div>


                        <div class="col-md-12 col-12 pt-4 pt-lg-3" data-aos="fade-up">
                            <img width={"100%"} height={"auto"} src={mediabanner} alt="Media Banner" />
                        </div>

                        


                        



                        <div class="col-md-6 col-12 pt-4 pt-lg-3" data-aos="fade-up">

                            <div className="section-title">
                                <h2>KTN Home TV</h2>
                            </div>
                            <p>
                                The segment showcased our innovative methods in Forex trading
                                education and our dedication to empowering traders with the
                                knowledge and skills needed for success. Being highlighted on such a
                                prestigious platform underscores our commitment to excellence and
                                our ongoing efforts to support and develop a thriving community of
                                informed and skilled traders.
                            </p>
                            <button className="student-portal-button"><a href="/testimonials">What Our Clients Say</a></button>
                        </div>

                        <div class="col-md-6 col-12 pt-4 pt-lg-3" data-aos="fade-up">
                            <div className="section-title">
                                <h2>Daily Nation</h2>
                            </div>
                            <p>
                                Financial Hub was recently interviewed and featured in the Daily
                                Nation, the leading local newspaper. The feature highlighted our
                                innovative approach to Forex trading education, emphasizing our
                                commitment to empowering traders with comprehensive strategies,
                                risk management techniques, and the psychological insights necessary
                                for success in the dynamic Forex market. We are honored to be
                                recognized for our contributions and dedication to fostering a
                                community of informed and skilled traders.
                            </p>

                            
                        </div>


                        
                        

                        

                        


                    </div>

                </div>
            </section>


           

        </>
    );
}
