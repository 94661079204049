import React, { useState } from "react";
import { GetDataLayerStateValue } from "../../utils/context";

export function PasswordField({ label, name }) {
	const [{ password, password2 }, dispatch] = GetDataLayerStateValue();
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const handleChange = (e) => {
		const fieldName = e.target.name;
		const value = e.target.value;
		dispatch({ type: "SET_CREDENTIALS", value: { [fieldName]: value } });
	};

	return (
		<div className="form-group">
			{/* <label>{label}</label> */}
			<div className="input-group">
				<input
					className="form-control"
					type={isPasswordVisible ? "text" : "password"}
					name={name}
					onChange={handleChange}
					value={name === "password" ? password : password2}
					placeholder={
						name === "password" ? "Enter password" : "Confirm password"
					}
				/>
				<div className="input-group-addon">
					<i
						className={isPasswordVisible ? "fa fa-eye-slash" : "fa fa-eye"}
						onClick={() => setIsPasswordVisible(!isPasswordVisible)}
					></i>
				</div>
			</div>
		</div>
	);
}

export function EmailField({ label, name }) {
	const [{ email, email2 }, dispatch] = GetDataLayerStateValue();

	const handleChange = (e) => {
		const fieldName = e.target.name;
		const value = e.target.value;
		dispatch({ type: "SET_CREDENTIALS", value: { [fieldName]: value } });
	};
	return (
		<div className="form-group">
			{/* <label>{label}</label> */}
			<div className="input-group">
				<input
					className="form-control"
					type="email"
					name={name}
					onChange={handleChange}
					value={name === "email" ? email : email2}
					placeholder={name === "email" ? "Enter email" : "Confirm email"}
				/>
			</div>
		</div>
	);
}

export const NameField = () => {
	const [{ username }, dispatch] = GetDataLayerStateValue();

	const handleChange = (e) => {
		dispatch({ type: "SET_CREDENTIALS", value: { username: e.target.value } });
	};
	return (
		<div className="form-group">
			{/* <label>Name</label> */}
			<div className="input-group">
				<input
					className="form-control"
					type="text"
					onChange={handleChange}
					value={username}
					placeholder="Enter your name"
				/>
			</div>
		</div>
	);
};
