import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import PodcastCard from "../components/Cards/PodcastCard";
import Breadcrumb from "../components/Hero/Breadcrumb";
import Loading from "../components/Loading/Loading";
import { axiosInstance } from "../utils/axios";
import { GetDataLayerStateValue } from "../utils/context";
import { scrollToTop } from "../utils/utils";
import SpotifyImage from "../img/spotify.png";

export default function PodcastsPage() {
  const [{ isLoading }, dispatch] = GetDataLayerStateValue();
  const [podcasts, setPodcasts] = useState([]);
  const [pagination, setPagination] = useState({
    url: "/api/v1/podcast",
    next: "",
    previous: "",
    pageNumber: 1,
  });

  const paginationButtonAction = (action) => {
    if (action === "next") {
      setPagination({
        ...pagination,
        pageNumber: pagination.pageNumber + 1,
        url: pagination.next.replace(
          "https://harmannkibue.opalstacked.com",
          ""
        ),
      });
    } else if (action === "previous") {
      setPagination({
        ...pagination,
        pageNumber: pagination.pageNumber - 1,
        url: pagination.previous.replace(
          "https://harmannkibue.opalstacked.com",
          ""
        ),
      });
    }
    scrollToTop();
  };

  const getPodcasts = useCallback(() => {
    dispatch({ type: "SET_IS_LOADING", payload: true });
    axiosInstance
      .get(pagination.url)
      .then((response) => {
        const data = response.data;
        // console.log("podcasts >>>>", data);
        setPodcasts(data.results);
        setPagination({
          ...pagination,
          next: data.next,
          previous: data.previous,
        });
        dispatch({ type: "SET_IS_LOADING", payload: false });
      })
      .catch((error) => console.log("The podcast fetch error is >>>", error));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination]);

  useEffect(() => {
    getPodcasts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination.pageNumber]);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <main id="main">
      <Breadcrumb page="Podcasts" title="Podcasts" />
      <section id="team" className="team section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>The Trader's show</h2>
            <p>
            A show for forex traders by forex traders. We share different forex journeys people take 
              before becoming consistently profitable forex traders.
            </p>
            <ListenOnText>Listen On <a href="https://open.spotify.com/show/1TGSk7aqxnyXzu1nj6ljCE?si=bca6da63fea54e64&nd=1" target="_blank" rel="noreferrer"><img src={SpotifyImage} style={{height: "30px", width: "30px"}} alt="spotify"></img></a></ListenOnText>
          </div>         

          <div className="row">
            {podcasts.map((podcast) => {
              const { id, title, description, image, audio } = podcast;
              return (
                <div className="col-lg-6 mb-4" key={id}>
                  <PodcastCard
                    id={id}
                    title={title}
                    description={description}
                    image={image}
                    audio={audio}
                  />
                </div>
              );
            })}
            <div className="col-md-12 col-12">
              {podcasts.length > 0 && (
                <div className="blog-pagination">
                  <ul className="justify-content-center">
                    <li>
                      {pagination.previous && (
                        <span
                          onClick={() => paginationButtonAction("previous")}
                        >
                          Previous
                        </span>
                      )}
                    </li>
                    <li className="active">
                      <span>{pagination.pageNumber}</span>
                    </li>
                    <li>
                      {pagination.next && (
                        <span onClick={() => paginationButtonAction("next")}>
                          Next
                        </span>
                      )}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}


const ListenOnText = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  color: black;
  font-style: none;
  text-decoration: none;
`;
