import React from "react";
import {Link } from "react-router-dom";
import { scrollToTop } from "../../utils/utils";


export default function Card({ article }) {
  const { title, image, description, slug } = article;
  return (
    <Link to={`/article/${slug}`} onClick={() => scrollToTop()} className="home-article-card">
    {/* <div > */}
      <div className="card-image-div"><img src={image} alt="text" className="card-image"></img></div>
     <h3 className="article-card-title">
       {title}
      </h3>
      <div className="article-card-description">
       
        <p>
          {description && (
            <p
              dangerouslySetInnerHTML={{
                __html: `${description.slice(0, 200)}...`,
              }}
            />
          )}
        </p>
      </div>
    {/* </div> */}
    </Link>
  );
}
