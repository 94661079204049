import React from "react";
import { Link } from "react-router-dom";
import pageLinks from "../utils/links";
import { scrollToTop } from "../utils/utils";

const ResetPassword = () => {
	return (
		<main>
			<section id="icon-boxes" className="icon-boxes login-area">
				<div className="container">
					<div className="row">
						<div className="col-md-3"></div>
						<div className="col-md-6 align-items-stretch mb-5">
							<div className="icon-box mt-5">
								<form>
									<h6 className="text-center mb-2">Reset Password</h6>
									<p className="mb-2">
										Enter the email you registered with and the password reset
										link will be sent to you
									</p>

									<div className="form-group mt-4">
										{/* <label>Email</label> */}
										<div className="input-group">
											<input
												className="form-control"
												type="text"
												placeholder="Enter email"
											/>
										</div>
									</div>

									<div className="text-center mt-4">
										<button className="btn btn-primary" type="submit">
											Send Password Reset Email
										</button>
									</div>
								</form>
								<p className="text-center mt-2">
									Already have an account?{" "}
									<Link to={pageLinks.login.url} onClick={() => scrollToTop}>
										Login here
									</Link>
								</p>
							</div>
						</div>
						<div className="col-md-3"></div>
					</div>
				</div>
			</section>
		</main>
	);
};

export default ResetPassword;
