import React from "react";

export default function SidebarSearch() {
	return (
		<div className="sidebar-item search-form">
			<form method="get">
				<input type="text" name="search" placeholder="search article..." />
				<button type="submit">
					<i className="bi bi-search"></i>
				</button>
			</form>
		</div>
	);
}
