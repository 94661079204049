import React from "react";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";
import pageLinks from "../../utils/links";
import { scrollToTop } from "../../utils/utils";

export default function Footer() {
  return (
    <>
      <footer id="footer">
        <div className="footer-newsletter">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h4>Disclaimer! </h4>
                <p>
                  All content published and distributed by Financial hub and its
                  affiliates (collectively, the “Company”) is to be treated as
                  general information only. None of the information provided by
                  Financial hub or contained here is intended (a) as investment
                  advice, (b) as an offer or solicitation of an offer to buy or
                  sell or (c) as a recommendation, endorsement or sponsorship of
                  any security, company or fund. Futures and forex trading
                  contains substantial risk and is not for every investor. An
                  investor could potentially lose all or more than the initial
                  investment. Risk capital is money that can be lost without
                  jeopardizing ones’ financial security or lifestyle. Only risk
                  capital should be used for trading and only those with
                  sufficient risk capital should consider trading. This is
                  neither a solicitation nor an offer to buy or sell futures,
                  options or forex. Past performance is not necessarily
                  indicative of future results.
                </p>
              </div>
              <div className="col-lg-6">
                {/* <form action="" method="post">
                            <input type="email" name="email"><input type="submit" value="Subscribe"/>
                        </form> */}
              </div>
            </div>
          </div>
        </div>

        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-links">
                <Link to="/" className="logo">
                  <img
                    src={logo}
                    alt="logo"
                    className="img-fluid"
                    width="30%"
                  />
                </Link>
                <p>Financial hub is a company that is focused on training, funding, and mentoring forex traders . The company was started in 2018 by three young, passionate forex traders out of a two-bedroom apartment in Nairobi.</p>
              </div>

              <div className="col-lg-3 col-md-6 col-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link to={pageLinks.home.url} onClick={() => scrollToTop()}>
                      {pageLinks.home.name}
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to={pageLinks.products.url}
                      onClick={() => scrollToTop()}
                    >
                      {pageLinks.products.name}
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to={pageLinks.about.url}
                      onClick={() => scrollToTop()}
                    >
                      {pageLinks.about.name}
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to={pageLinks.contact.url}
                      onClick={() => scrollToTop()}
                    >
                      {pageLinks.contact.name}
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 col-6 footer-links">
                <h4>Content</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to={pageLinks.educationalArticles.url}
                      onClick={() => scrollToTop()}
                    >
                      {pageLinks.educationalArticles.name}
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to={pageLinks.psychologyArticles.url}
                      onClick={() => scrollToTop()}
                    >
                      {pageLinks.psychologyArticles.name}
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to={pageLinks.cryptoArticles.url}
                      onClick={() => scrollToTop()}
                    >
                      {pageLinks.cryptoArticles.name}
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to={pageLinks.school.url}
                      onClick={() => scrollToTop()}
                    >
                      {pageLinks.school.name}
                    </Link>
                  </li>

                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      to={pageLinks.podcasts.url}
                      onClick={() => scrollToTop()}
                    >
                      {pageLinks.podcasts.name}
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-info">
                <h4>About Us</h4>
                <p>
                  Nairobi, Kenya
                  <br />
                  <strong>Phone:</strong> +254 757 640 166
                  <br />
                  <strong>Email:</strong> <a href="mailto:info@financialhubfx.com">info@financialhubfx.com</a>
                  <br />
                </p>
                <div className="social-links mt-3">
                  <a
                    href="https://twitter.com/financialhub_ke/"
                    className="twitter"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-twitter"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/financialhubke/"
                    className="facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/financial_hub/"
                    className="instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/financial-hub-forex-academy/"
                    className="linked-in"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>FinancialHub</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits"></div>
        </div>
      </footer>
    </>
  );
}
