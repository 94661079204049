/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import SchoolCard from "../components/Cards/SchoolCard";
import Breadcrumb from "../components/Hero/Breadcrumb";
import Loading from "../components/Loading/Loading";
import { axiosInstance } from "../utils/axios";
import { GetDataLayerStateValue } from "../utils/context";

export default function School() {
  const [{ isLoading }, dispatch] = GetDataLayerStateValue();
  const [videos, setVideos] = useState([]);
  const [urls, setUrls] = useState({
    url: "/api/v1/school/",
    nextUrl: "",
    previousUrl: "",
  });
  const [videoID, setVideoID] = useState("null");
  const [videoError, setVideoError] = useState(null);

  const getSchoolVideos = useCallback(() => {
    dispatch({ type: "SET_IS_LOADING", payload: true });
    dispatch({ type: "SET_IS_ERROR", payload: false });
    axiosInstance
      .get(urls.url)
      .then((response) => {
        const data = response.data;
        setVideos(data.results);
        setUrls({ ...urls, previousUrl: data.previous, nextUrl: data.next });
        dispatch({ type: "SET_IS_LOADING", payload: false });
      })
      .catch((error) => {
        console.log(error);
      });
  },[urls, dispatch]);

  const getLatestYTVideo = async () => {
    const currentChannelId = "UCGZ595tFkjl4UOH3S9_GQ7Q";
    const baseUrl =
      "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3D";
    try {
      const data = await fetch(`${baseUrl}${currentChannelId}`).then(
        (response) => response.json()
      );
      var link = data.items[0].link;
      var id = link.substr(link.indexOf("=") + 1);
	  setVideoID(id);
    } catch (error){
      setVideoError("Error fetching latest video");
    }
  };

  useEffect(() => {
    getSchoolVideos();
    getLatestYTVideo();
  }, []);

  if (isLoading || videos.length === 0) {
    return <Loading />;
  }
  return (
    <main id="main">
      <Breadcrumb page="School" title="School" />
      <section id="team" className="team section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title" style={{marginTop: "-1.5em"}}>
            <h2>Free School</h2>
            <p>
              This short course can help you understand the basics of forex
              trading. Start your trading journey here!
            </p>
          </div>
          <div className="yt-player-div">
			        <iframe
                title="YT video"
                id="youtube_video"
                frameborder="0"
				        src={`https://www.youtube.com/embed/${videoID}?controls=0&showinfo=0&rel=0`}
                className="yt-player" allowFullScreen></iframe>
          </div>
          {videoError && <p>{videoError}</p>}
          <div className="row">
            {videos.map((video) => {
              const { id, title, description, image, youtube_id } = video;

              return (
                <div className="col-lg-6 free-education" key={id}>
                  <SchoolCard
                    title={title}
                    description={description}
                    image={image}
                    videoId={youtube_id}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </main>
  );
}
