import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp-button'
import 'react-floating-whatsapp-button/dist/index.css'


export default function WhatsAppChatFloat() {
    return (
        <FloatingWhatsApp phone="+254704732296" size="60px" autoOpenTimeout="200000"/>
    )
}


